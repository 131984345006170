import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import GoogleMaps from "simple-react-google-maps";

const aboutbg = require('./../../assets/images/background/image-11.jpg');




class Contact extends Component {
    submitForm=(e)=>{
        e.preventDefault();
        const payload={
            name:e.target[0].value,
            phone:e.target[1].value,
            email:e.target[2].value,
            message:e.target[3].value
        }
        console.log(payload);
    }
    render() {
        
        return (
            <>
                <Header />
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Contact Us</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Contact</li>
                        </ul>
                    </div>

                </section>

                {/* <!-- Map Section --> */}
                {/* <!-- Map Section --> */}
                <section class="map-section">
                    <div class="map-column">
                        <GoogleMaps
                            apiKey={"AIzaSyBJp_UaaO9VeLyF2a1gQWXZvhuz1aCuB4s"}
                            style={{ height: "800px", width: "100%" }}
                            zoom={12} 
                            center={{ lat: 24.85584825833699, lng: 67.06044257620432 }}
                            markers={{ lat: 24.85584825833699, lng: 67.06044257620432 }} //optional
                        />
                    </div>
                </section>

                {/* <!-- Contact Section Two --> */}
                <section class="contact-section-two">
                    <div class="auto-container">
                        <div class="contact-info-area">
                            <div class="contact-info">
                                <div class="row">
                                    <div class="info-column col-lg-4">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-email-6"></span></div>
                                            <h3>Email Address</h3>
                                            <ul>
                                                <li><a href={'/mailto:info@mtcpvt.com'}>info@mtcpvt.com</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="info-column col-lg-4">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-call-1"></span></div>
                                            <h3>Phone Number</h3>
                                            <ul>
                                                <li><a href={'/tel:+923002029017'}>+923 002 029 017</a></li>
                                                <li><a href={'/tel:+923122029007'}>+923 122 029 007</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="info-column col-lg-4">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-location"></span></div>
                                            <h3>Office Address</h3>
                                            <ul>
                                                <li>263P PECHS Block 6, Karachi</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-form-area">
                            <div class="sec-title text-center">
                                <div class="sub-title">Write Here</div>
                                <h2>Get In Touch</h2>
                            </div>
                            {/* <!-- Contact Form--> */}
                            <div class="contact-form">
                                <form method="post" onSubmit={this.submitForm} id="contact-form">
                                    <div class="row clearfix">                                    
                                        <div class="col-md-6 form-group">
                                            <label htmlFor="name">Enter your name</label>
                                            <input type="text" name="username" id="name" placeholder="Enter name here......" required=""/>
                                            <i class="fas fa-user"></i>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label htmlFor="phone">Enter your phone</label>
                                            <input type="text" name="phone" id="phone" placeholder="Enter phone here......" required=""/>
                                            <i class="fas fa-phone"></i>
                                        </div>
                                        
                                        <div class="col-md-12 form-group">
                                            <label htmlFor="email">Enter your email</label>
                                            <input type="email" name="email" id="email" placeholder="Enter email here......" required=""/>
                                            <i class="fas fa-envelope"></i>
                                        </div>
                
                                        <div class="col-md-12 form-group">
                                            <label htmlFor="message">Enter your business requirement and budget</label>
                                            <textarea name="message" id="message" placeholder="Enter text here......"></textarea>
                                            <i class="fas fa-edit"></i>
                                        </div>
                
                                        <div class="col-md-12 form-group">
                                            <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">Get In Touch</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>






                <Footer />
            </>
        )
    }
}
export default Contact;