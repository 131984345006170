import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from './pages/index';
import Error404 from './pages/404';
import About from './pages/about'
import Careers from './pages/careers'
import Contact from './pages/contact'
import Faq from './pages/faq'
import Services from './pages/services'
import TeamDetails from './pages/team-details'
import Team from './pages/team';
import BackToTop from './layout/backToTop'


class Router extends Component {
    render() {
        return (
            <BrowserRouter >
            {/* <BrowserRouter> */}
                <div class="page-wrapper">

                    <Switch>
                        <Route path='/' exact component={Index} />
                        <Route path='/404' component={Error404} />
                        <Route path='/about' component={About} />
                        <Route path='/careers' component={Careers} />
                        <Route path='/contact' component={Contact} />
                        <Route path='/faq' component={Faq} />
                        <Route path='/services' component={Services} />
                        <Route path='/team-details' component={TeamDetails} />
                        <Route path='/team' component={Team} />

                    </Switch>

                    <BackToTop />

                </div>
            </BrowserRouter>

        )
    }
}

export default Router;