import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header';
import Footer from '../layout/footer';
import HomeSlider1 from './../element/home-slider1';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

// import '../../assets/css/color.css';

import VideoPopup1 from './../element/video-popup1';

const teambg1 = require('./../../assets/images/background/image-3.jpg');
const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');

class Index extends Component {
    submitForm=(e)=>{
        e.preventDefault();
        const payload={
            name:e.target[0].value,
            phone:e.target[1].value,
            email:e.target[2].value,
            message:e.target[3].value
        }
        console.log(payload);
    }

    render() {
        return (
            <>
                <Header />
                <HomeSlider1 />



                {/* <!-- Welcome Setion --> */}
                <section class="welcome-section pt-0">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="row m-0">
                                {/* <!-- Welcome Block One --> */}
                                <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="flaticon-analysis"></span></div>
                                        <h2>Monitor Your Cash Flow</h2>
                                        <div class="text">Elevate your business with our expert financial statement services. Gain clarity, make informed decisions, and achieve financial success seamlessly. </div>
                                        <div class="read-more"><Link to={'/services?service=accounting'}>Read More</Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="inner-box" style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}>
                                        <div class="icon-box"><span class="flaticon-tax"></span></div>
                                        <h2>Save Your Tax</h2>
                                        <div class="text">Our focus on optimizing tax strategies within legal boundaries leads to substantial savings and decreased tax liabilities. Staying updated on tax laws is key to effectively minimizing your tax burden.</div>
                                        <div class="read-more"><Link to={'/services?service=taxation'}>Read More</Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="flaticon-work-1"></span></div>
                                        <h2>Run Your Business Online</h2>
                                        <div class="text">Embrace Ecommerce for seamless online operations. Elevate your business by reaching a nation wide audience and maximizing sales opportunities effortlessly. </div>
                                        <div class="read-more"><Link to={'/services?service=ecommerce'}>Read More</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* <!-- About Section --> */}
                <section class="about-section">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one">
                                        <img src={require('../../assets/images/resource/image-2.jpg')} alt="" />
                                    </div>
                                    <div class="image-two wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="caption">F.</div>
                                        <div class="image-outer"><img src={require('../../assets/images/resource/image-3.jpg')} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">About Us</div>
                                        <h2>We Collaborate With You to Fulfill Diverse Business Needs.</h2>
                                        <div class="text">From meticulous accounting and financial statement preparation to intelligent tax solutions, including tax savings strategies, as well as the creation and proficient management of Daraz stores. Additionally, our expertise extends to comprehensive internal auditing services, ensuring operational integrity and compliance.</div>
                                    </div>
                                    <div class="row">
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><img width={"100px"} src={require('../../assets/images/icons/icon-1.png')} alt="" /></div>
                                                <h5>Phone Number</h5>
                                                <h2><a style={{textDecoration:'None',color:'black'}} href="tel:+923002029017">+923 002 029 017</a></h2>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><img width={"100px"} src={require('../../assets/images/icons/icon-2.png')} alt="" /></div>
                                                <h5>Email Address</h5>
                                                <h2><a style={{textDecoration:'None',color:'black'}} href="mailto:info@mtcpvt.com">info@mtcpvt.com</a></h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Services Section --> */}
                <section class="services-section">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column">
                                <div class="services-content">
                                    <div class="sec-title light">
                                        <div class="sub-title">Our Services</div>
                                        <h2>What do we offer?</h2>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                        <h2>Financial & Management Services</h2>
                                        <div class="text">MACRO TECH CONSULTANTS provides top-notch Accounting and Finance outsourcing solutions. We act as your internal finance and accounting department, ensuring cost-effective services without compromising quality. </div>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                        <h2>Direct and Indirect Tax Services</h2>
                                        <div class="text">Our services aim to help clients, both companies and individuals, identify key tax planning opportunities and develop tax strategies to minimize tax liabilities. </div>
                                    </div>
                                    <div class="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                        <h2>ECommerce Services</h2>
                                        <div class="text">Daraz Store Experts: We create and manage stores, boosting sales and amplifying visibility for your business on Daraz. </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-column">
                                {/* <!-- Fact Counter --> */}
                                <div class="fact-counter">
                                    <div class="border-box">
                                        <div class="border_top"></div>
                                        <div class="border_bottom"></div>
                                        <div class="border_middile"></div>
                                    </div>
                                    <div class="row">

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-6.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={90}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div class="counter-title">Accounting Services</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-7.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={95}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div class="counter-title">Taxation Services</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-8.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={86}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div class="counter-title">Ecommerce Services</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img src={require('../../assets/images/icons/icon-9.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={89}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div class="counter-title">Internal Auditing</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img width={"95px"} src={require('../../assets/images/icons/icon-10.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={92}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div class="counter-title">Data Analysis</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div class="column counter-column col-md-6">
                                            <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div class="content">
                                                    <div class="icon"><img width={"95px"} src={require('../../assets/images/icons/icon-11.png')} alt="" /></div>
                                                    <div class="count-outer count-box">
                                                        <CountUp
                                                            end={85}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span class="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div class="counter-title">Digital Marketing</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="author-box" style={{ "backgroundImage": "url(assets/images/background/image-1.jpg)" }}>
                                    <div class="author-info">
                                        <div class="author-thumb"><img width={"150"} src={require('../../assets/images/resource/author-2.jpg')} alt="" /></div>
                                        <h2>Noman Shakir</h2>
                                        <div class="designation">Chief Executive Officer</div>
                                        <div class="text">Macro Tech Consultants Private Limited</div>
                                    </div>
                                    <div class="signature"><img width={"300"} src={require('../../assets/images/resource/Signature-3.png')} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* <!-- Gallery Section -->
                <PortfolioFilter1 /> */}

                {/* <!-- Why Choose Us Section --> */}
                <section class="why-chooseus-section">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">Why Choose Us</div>
                                        <h2>Why Should You <br />Choose Us ?</h2>
                                    </div>
                                    <Tab.Container defaultActiveKey="first">
                                        <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"><span>Our Mission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"><span>Our Vission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><span>Our Value</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="tabs-content">
                                            <Tab.Pane eventKey="first" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    <div class="text">
                                                        <ul>
                                                            <li>
                                                            •   <b>Deliver Bespoke Financial Strategies:</b> Tailoring Accounting and Tax Advisory services to meet specific business needs, ensuring financial stability and growth.
                                                            </li>
                                                            <li>
                                                            •   <b>Elevate E-commerce Success:</b> Providing cutting-edge solutions to maximize online presence and sales for businesses in the digital landscape.
                                                            </li>
                                                            <li>
                                                            •   <b>Safeguard Integrity:</b> Conducting thorough Internal Audits to fortify operational integrity, compliance, and risk management.
                                                            </li>
                                                            <li>
                                                            •   <b>Drive Sustainable Growth:</b> Offering comprehensive consultancy, guiding businesses towards sustained success and global competitiveness.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    <div class="text">
                                                        <b>Empowering Business Excellence Through Strategic Consultancy:</b>
                                                    <ul>
                                                            <li>
                                                            
                                                                We envision fostering financial prowess and operational brilliance for businesses worldwide, pioneering innovative solutions in Accounting, Tax Advisory, E-commerce Enhancement, and Internal Auditing

                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" className="fadeInUp animated">
                                                <div class="clearfix">
                                                    <div class="text">
                                                        <ul>
                                                            <li>
                                                            •	<b>Expertise and Diligence:</b> We maintain the highest standards of professional knowledge and skills, ensuring competent services for our clients.
                                                            </li>
                                                            <li>
                                                            •	<b>Unwavering Integrity:</b> Honesty and transparency govern all our professional and business relationships.
                                                            </li>
                                                            <li>
                                                            •	<b>Respectful Confidentiality:</b> We safeguard and honor the confidentiality of information obtained, never disclosing it to third parties.
                                                            </li>
                                                            <li>
                                                            •	<b>Ethical Conduct:</b> Adhering strictly to laws and regulations, our team conducts itself in a manner that upholds our profession's esteemed reputation.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>



                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one"><img src={require('../../assets/images/resource/image-6.jpg')} alt="" /></div>
                                    <div class="image-two"><img src={require('../../assets/images/resource/image-5.jpg')} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <!-- Video Section --> */}
                <VideoPopup1 />




                {/* <!-- Team Section --> */}
                <section class="team-section" style={{ backgroundImage: "url(" + teambg1 + ")" }}>
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Our Team</div>
                            <h2>Leadership Team</h2>
                        </div>
                        <div class="row">
                            {/* <!-- Team Block One --> */}
                            <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-4.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">Ecommerce Specialist</div>
                                        <h3>Humna Sajid</h3>
                                        <div class="text">Proficient in launching and managing online E-commerce platforms.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">CEO</div>
                                        <h3>Noman Shakir</h3>
                                        <div class="text">Skilled in preparing Financial Statements, executing Internal Audits, and handling Tax Return Filing with extensive expertise and a wealth of experience.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-3.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">Data Scientist</div>
                                        <h3>Ammad Hassan</h3>
                                        <div class="text">Skilled in Data Analysis, Data Engineering, and Artificial Intelligence with extensive expertise.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Contact Section --> */}
                <section class="contact-section">
                    <div class="auto-container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="sec-title">
                                    <div class="sub-title">Write Here</div>
                                    <h2>Get In Touch</h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div class="contact-form">
                                    <form method="post" onSubmit={this.submitForm} id="contact-form">
                                        <div class="row clearfix">
                                            <div class="col-md-12 form-group">
                                                <label htmlFor="name">Enter your name</label>
                                                <input type="text" name="username" id="name" placeholder="Enter name here......" required="" />
                                                <i class="fas fa-user"></i>
                                            </div>
                                            <div class="col-md-12 form-group">
                                                <label htmlFor="phone">Enter your phone</label>
                                                <input type="text" name="phone" id="phone" placeholder="Enter phone here......" required="" />
                                                <i class="fas fa-phone"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <label htmlFor="email">Enter your email</label>
                                                <input type="email" name="email" id="email" placeholder="Enter email here......" required="" />
                                                <i class="fas fa-envelope"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <label htmlFor="message">Enter your business requirement and budget</label>
                                                <textarea name="message" id="message" placeholder="Enter text here......"></textarea>
                                                <i class="fas fa-edit"></i>
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">Get In Touch</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="sec-title">
                                    <div class="sub-title">Get Us Here</div>
                                    <h2>Contact Us</h2>
                                </div>
                                <div class="contact-info">
                                    <div class="border-box">
                                        <div class="border_top"></div>
                                        <div class="border_middile"></div>
                                    </div>
                                    <div class="row">
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-email-6"></span></div>
                                                <h3>Email Address</h3>
                                                <ul>
                                                    <li><a href={'/mailto:info@mtcpvt.com'}>info@mtcpvt.com</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-call-1"></span></div>
                                                <h3>Phone Number</h3>
                                                <ul>
                                                    <li><a style={{textDecoration:'None',color:'black'}} href="tel:+923002029017">+923 002 029 017</a></li>
                                                    <li><a style={{textDecoration:'None',color:'black'}} href="tel:+923122029007">+923 122 029 007</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-location"></span></div>
                                                <h3>Office Address</h3>
                                                <ul>
                                                    <li>263P PECHS Block 6, Karachi</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="info-column col-md-6">
                                            <div class="icon-box">
                                                <div class="icon"><span class="flaticon-worldwide"></span></div>
                                                <h3>Web Connect</h3>
                                                <ul>
                                                    <li><a href={'https://www.mtcpvt.com/'}>mtcpvt.com</a></li>
                                                    {/* <li><a href={'/http://jobs.example.com/'}>jobs.example.com</a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}
export default Index;