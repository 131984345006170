import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';


const aboutbg = require('./../../assets/images/background/image-11.jpg');



class About extends Component {


    render() {
        return (
            <>
                <Header />
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>About Us</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">About Us</li>
                        </ul>
                    </div>

                </section>


                {/* About Section Two */}
                <section class="about-section-two">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one">
                                        <img src={require('../../assets/images/resource/image-10.jpg')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">About Us</div>
                                        <h2>We Collaborate With You to Fulfill Diverse Business Needs</h2>
                                        <div class="text">From meticulous accounting and financial statement preparation to intelligent tax solutions, including tax savings strategies, as well as the creation and proficient management of Daraz stores. Additionally, our expertise extends to comprehensive internal auditing services, ensuring operational integrity and compliance.</div>
                                    </div>
                                    <div class="author-info">
                                        <div class="wrapper-box">
                                            <h2>Noman Shakir</h2>
                                            <div class="designation">CEO</div>
                                            <div class="text">An Associate Member of the Institute of Cost and Management Accountants of Pakistan, Noman holds a Master's in Economics from the University of Karachi. With over 10 years in finance and accounting, he serves as CFO & Company Secretary at 786 Investments Ltd, a publicly listed company under NBFC 2003. He also holds the CFO position at Dawood Global Foundation, registered under the societies act 1860.</div>
                                        </div>
                                        <div class="signature"><img src={require('../../assets/images/resource/Signature-3.png')} alt="" /></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                                {/* Services Section Four */}
                <section class="services-section-four services-section-about">
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Features</div>
                            <h2>Core Features</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt="" /></div>
                                    <h2>Accounting Services</h2>
                                    <div class="text">Management reporting, budgeting, cash flow analysis, reconciliations, invoicing, and accounting system training.</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img width={"50px"} src={require('../../assets/images/icons/icon-21.png')} alt="" /></div>
                                    <h2>Audit & Assurance Services</h2>
                                    <div class="text">
                                    Statutory audit, financial statement assistance, internal control reviews, and due diligence reports.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt="" /></div>
                                    <h2>Internal Audit Services</h2>
                                    <div class="text">
                                    Reviewing internal control systems and recommending improvements.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img width={"50px"} src={require('../../assets/images/icons/icon-22.png')} alt="" /></div>
                                    <h2>Tax Services</h2>
                                    <div class="text">
                                        Tax planning, income tax registration, return preparation, sales tax filing, and company incorporation.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>Digital Marketing</h2>
                                    <div class="text">
                                    Brand strategy, content creation, web design, social media, and advertising. 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img width={"50px"} src={require('../../assets/images/icons/icon-23.png')} alt="" /></div>
                                    <h2>Software Services</h2>
                                    <div class="text">
                                    Tailored accounting, ERP, and HR systems.    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Team Section */}
                <section class="team-section">
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Our Team</div>
                            <h2>Leadership Team</h2>
                        </div>
                        <div class="row">
                            {/* Team Block One */}
                            <div class="col-lg-4 team-block-one">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-4.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">Ecommerce Specialist</div>
                                        <h3>Humna Sajid</h3>
                                        <ul class="social-links">
                                            <li><Link to={'/#'}><span class="fab fa-facebook-f"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-linkedin"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Team Block One */}
                            <div class="col-lg-4 team-block-one">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">CEO</div>
                                        <h3>Noman Shakir</h3>
                                        <ul class="social-links">
                                            <li><Link to={'/#'}><span class="fab fa-facebook-f"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-behance"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Team Block One */}
                            <div class="col-lg-4 team-block-one">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-3.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">Data Scientist</div>
                                        <h3>Ammad Hassan</h3>
                                        <ul class="social-links">
                                            <li><Link to={'/#'}><span class="fab fa-facebook-f"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-behance"></span></Link></li>
                                            <li><Link to={'/#'}><span class="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* Sponsors Section */}

{/* 
                <Brand1 /> */}






                <Footer />
            </>
        )
    }
}
export default About;