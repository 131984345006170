import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header'
import Footer from '../layout/footer'
import FaqAccordion from '../element/faq-accordion';

const aboutbg = require('./../../assets/images/background/image-11.jpg');

export default function Services (){

        const [service,setService]=useState("accounting");
        useEffect(()=>{
            setService(window.location.search.split("=")[1]!==undefined?window.location.search.split("=")[1]:'accounting');
        },[])
        return (
            <>
                 <Header/>
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Our Services</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Services</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}


                {/* <!-- Services Section Five --> */}
                <section class="services-section-five">
                    <div class="auto-container">
                        <Tab.Container activeKey={service} defaultActiveKey={service} onSelect={(key) => setService(key)}>
                            <Nav className="nav-tabs tab-btn-style-one">
                                <Nav.Item>
                                    <Nav.Link eventKey="accounting">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-team"></span></div>
                                            <h5>Accounting</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="taxation">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-money"></span></div>
                                            <h5>Taxation</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ecommerce">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-assets"></span></div>
                                            <h5>Ecommerce</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="audit">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-world"></span></div>
                                            <h5>Internal Auditing</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ai">
                                    <div class="icon-box">
                                        <div class="icon"><span class="flaticon-money-1"></span></div>
                                        <h5>Artificial Intelligence</h5>
                                    </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="digitalmarketing">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-notebook"></span></div>
                                            <h5>Digital Marketing</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="accounting" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt=""/></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title">
                                                    <div class="sub-title">Accouting Services</div>
                                                    <h2>Get in touch<br/>For Great Accouting Work.</h2>
                                                </div>
                                                <div class="text">
                                                    <p>We help you keep track of your business's performance and position with daily monitoring. MACRO TECH CONSULTANTS offers Accounting and Finance outsourcing solutions that reduce costs without compromising quality. We serve as an internal finance and accounting department, working closely with your CFO or Chief Accountant.</p>
                                                    <p><b>Key Services Include:</b></p>
                                                    <ul>
                                                        <li>
                                                            •	Preparation of management reports, budgets, cash flow forecasts, and variance reports.

                                                        </li>
                                                        <li>
                                                            •	Monthly bank reconciliations.
                                                            
                                                        </li>
                                                        <li>
                                                            •	Debtors and creditors statements and aging analysis.
                                                            
                                                        </li>
                                                        <li>
                                                            •	Financial statement preparation.
                                                            
                                                        </li>
                                                        <li>
                                                            •	Cash flow statements.
                                                            
                                                        </li>
                                                        <li>
                                                            •	Customer invoicing and data entry.
                                                            
                                                        </li>
                                                        <li>
                                                            •	Accounting systems selection and training.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="link-btn"><Link to={'/contact'} class="theme-btn btn-style-one"><span class="btn-title">Contact Us</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="taxation" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-15.jpg')} alt=""/></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title">
                                                    <div class="sub-title">Direct and Indirect Taxes</div>
                                                    <h2>Save your taxes <br/>with us.</h2>
                                                </div>
                                                <div class="text">
                                                    <p>
                                                        Our services aim to help clients, both companies and individuals, identify key tax planning opportunities and develop tax strategies to minimize tax liabilities.
                                                    </p>
                                                    <p><b>Key Services Include:</b></p>
                                                    <ul>
                                                        <li>
                                                        •	Income Tax/NTN Registration and e-Enrollment.
                                                        </li>
                                                        <li>
                                                        •	Annual return preparation.                                                            
                                                        </li>
                                                        <li>
                                                        •	Accounting records and financial statement preparation. 
                                                        </li>
                                                        <li>
                                                        •	Sales tax registration and filing.
                                                        </li>
                                                        <li>
                                                        •	Company incorporation with SECP.                                                           
                                                        </li>
                                                        <li>
                                                        •	Copyrights of Patents.                                                            
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="link-btn"><Link to={'/contact'} class="theme-btn btn-style-one"><span class="btn-title">Contact Us</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ecommerce" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-1.jpg')} alt=""/></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title">
                                                    <div class="sub-title">Ecommerce Management</div>
                                                    <h2>Bring Your Business Online.</h2>
                                                </div>
                                                <div class="text">
                                                    <p>Develop and maintain your Ecommerce Store online with us.</p>
                                                <p><b>Key Services Include:</b></p>
                                                    <ul>
                                                        <li>
                                                        •	Create Brand Store.
                                                        </li>
                                                        <li>
                                                        •	Products Management.
                                                        </li>
                                                        <li>
                                                        •	Maintain Inventory records.
                                                        </li>
                                                        <li>
                                                        •	Promote store with marketing.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="link-btn"><Link to={'/contact'} class="theme-btn btn-style-one"><span class="btn-title">Contact Us</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="audit" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-16.jpg')} alt=""/></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title">
                                                    <div class="sub-title">Internal Auditing Services</div>
                                                    <h2>Get aware of your weaknesses</h2>
                                                </div>
                                                <div class="text">
                                                    <p>We review your internal control systems to identify discrepancies and weaknesses and recommend improvements.</p>
                                                    <p><b>Key Services Include:</b></p>
                                                    <ul>
                                                        <li>
                                                        •	Bookkeeping.
                                                        </li>
                                                        <li>
                                                        •	Bank reconciliations.                                                            
                                                        </li>
                                                        <li>
                                                        •	General ledger.
                                                        </li>
                                                        <li>
                                                        •	Trial balance.
                                                        </li>
                                                        <li>
                                                        •	Stock review.                                                          
                                                        </li>
                                                        <li>
                                                        •	Fixed assets review.                                                 
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="link-btn"><Link to={'/contact'} class="theme-btn btn-style-one"><span class="btn-title">Contact Us</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ai" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-14.jpg')} alt=""/></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title">
                                                    <div class="sub-title">Data Analysis and Engineering Services</div>
                                                    <h2>Get Artificial Intelligence Powered Solutions.</h2>
                                                </div>
                                                <div class="text">
                                                    <p>Bring Artificial Intelligence to your business solution and make your business more profitable.</p>
                                                    <p><b>Key Services Include:</b></p>
                                                    <ul>
                                                        <li>
                                                        •	Data Analysis.
                                                        </li>
                                                        <li>
                                                        •	Data Engineering.
                                                        </li>
                                                        <li>
                                                        •	Artificial Intelligence Model Training.
                                                        </li>
                                                        <li>
                                                        •    Software Development.
                                                        </li>
                                                        <li>
                                                        •    Web Development.
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                                <div class="link-btn"><Link to={'/contact'} class="theme-btn btn-style-one"><span class="btn-title">Contact Us</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="digitalmarketing" className=" fadeInUp animated">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="image"><img src={require('../../assets/images/resource/image-17.jpg')} alt=""/></div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="content pl-lg-4">
                                                <div class="sec-title">
                                                    <div class="sub-title">Digital Marketing Services</div>
                                                    <h2>SEO, Social Media Marketing and Advertisement Compaigns.</h2>
                                                </div>
                                                <div class="text">
                                                <p>We develop marketing strategies tailored to your business and create brand identity through content creation, web development, and effective advertising.</p>
                                                    <p><b>Key Services Include:</b></p>
                                                    <ul>
                                                        <li>
                                                        •	Creation of Brand Identity.
                                                        </li>
                                                        <li>
                                                        •	Content Generation & Optimization.
                                                        </li>
                                                        <li>
                                                        •	Brand Strategy and Implementation.
                                                        </li>
                                                        <li>
                                                        •	Custom Website Design.
                                                        </li>
                                                        <li>
                                                        •	Social Media Development.
                                                        </li>
                                                        <li>
                                                        •	Indoor and Outdoor Advertisements.
                                                        </li>
                                                        <li>
                                                        •	Print Production.  
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                                <div class="link-btn"><Link to={'/contact'} class="theme-btn btn-style-one"><span class="btn-title">Contact Us</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>

                {/* <!-- Gallery Section --> */}
                <FaqAccordion/>

                {/* <!-- Sponsors Section --> */}

                {/* <Brand1/>
 */}



                 
                 <Footer/>
            </>
        );
}